<template>
    <div class="relative">
        <template v-if="loading">
            <div class="flex h-64 items-center justify-center">
                <font-awesome-icon
                    :icon="['fas', 'circle-notch']"
                    class="animate-spin text-4xl text-gray-900 dark:text-gray-demegro-light"
                />
            </div>
        </template>
        <template v-else>
            <div class="relative overflow-hidden">
                <div
                    class="flex transition-transform duration-300 ease-in-out"
                    :style="{ transform: `translateX(-${currentSlide * 100}%)` }"
                >
                    <div
                        v-for="(article, index) in articles"
                        :key="article.id"
                        class="w-[25%] flex-shrink-0"
                        :class="{
                            'pl-8': index % 4 !== 0 && index !== 0,
                            'pl-0': index % 4 === 0 && index !== 0,
                        }"
                    >
                        <HomeArticleBannerItem :article="article" />
                    </div>
                </div>
            </div>
            <div v-if="currentSlide > 0" class="absolute inset-y-0 left-0 flex items-center">
                <button
                    class="-ml-4 w-[35px] rounded-full bg-white/50 p-2 shadow-md transition-all duration-300 hover:bg-white dark:bg-gray-800/50 dark:hover:bg-dark-gray-demegro"
                    :disabled="currentSlide === 0"
                    @click="prevSlide"
                >
                    <font-awesome-icon
                        :icon="['fas', 'chevron-left']"
                        class="text-gray-demegro dark:text-gray-demegro-light"
                    />
                </button>
            </div>
            <div v-if="currentSlide < maxSlide" class="absolute inset-y-0 right-0 flex items-center">
                <button
                    class="-mr-4 w-[35px] rounded-full bg-white/50 p-2 shadow-md transition-all duration-300 hover:bg-white dark:bg-gray-800/50 dark:hover:bg-dark-gray-demegro"
                    :disabled="currentSlide === maxSlide"
                    @click="nextSlide"
                >
                    <font-awesome-icon
                        :icon="['fas', 'chevron-right']"
                        class="text-gray-demegro dark:text-gray-demegro-light"
                    />
                </button>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import type { Article } from '~/types/demegro'

const authenticated = toRef(useAuthStore(), 'authenticated')

const { data: articles, status } = await useFetch<Article[]>('/api/articles/bannerlist', {
    key: `banner-articles`,
    method: 'GET',
    watch: [authenticated],
    onResponse({ response }) {
        return response._data
    },
    lazy: true,
})

const loading = computed(() => status.value === 'pending')

const currentSlide = ref(0)

const maxSlide = computed(() => {
    if (!articles.value)
        return 4
    return Math.max(0, Math.ceil(articles.value.length / 4) - 1)
})

function nextSlide() {
    if (currentSlide.value < maxSlide.value) {
        currentSlide.value++
    }
}

function prevSlide() {
    if (currentSlide.value > 0) {
        currentSlide.value--
    }
}

function handleResize() {
    currentSlide.value = Math.min(currentSlide.value, maxSlide.value)
}

onMounted(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
})

onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize)
})
</script>

<template>
    <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        <div v-for="article in articles" :key="article.id" class="flex">
            <div class="relative h-full w-full rounded-lg bg-white transition-all duration-300 hover:drop-shadow-lg">
                <NuxtLink
                    :to="article.slug"
                    class="flex h-full flex-col"
                    :aria-label="!article._skeleton ? `Navigate to ${article.title} article` : 'Loading article'"
                >
                    <HomeDemedentArticlesListArticle :article="article" />
                </NuxtLink>
                <NuxtImg
                    v-if="article.is_demedent || article.manufacturer_name?.toUpperCase() === 'DEMEDENT'"
                    src="/no_risk_sticker.webp"
                    class="absolute top-3 right-3 z-10 size-20 rotate-[23deg]"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { Article } from '@/types/demegro'

const { skeletons, sequentiallyReplaceItems } = useSkeletonLoader<Article>(12)
const articles = ref(skeletons())

const { data } = await useFetch('/api/articles/demedent/list', {
    default: () => articles.value,
    key: 'articlesDemedentList',
    query: {
        count: 12,
        image: true,
    },
    lazy: true,
    immediate: true,
})

watchEffect(() => {
    if (!data.value?.length)
        return
    sequentiallyReplaceItems(articles, data.value)
})
</script>
